<template>
  <div class="page-container">
    
    <div class="section-container">
      <div class="box-header">
        <h3>Donation</h3>
        <p>Detta är text som någon har infogat på butikssidan.</p>

        <!-- Conditional rendering for CUSTOM_DONATION -->
        <div class="input-container">
          <memlist-text-input
            id="amount"
            name="amount"
            layout="vertical"
            :title="'Donationssumma SEK'"
            variation="enhanced"
            validation_type="TEXT"
            v-model="amount"
            :placeholder="$t('PUBLIC.FORM.CUSTOM_DONATION_AMOUNT')"
            :required="false"
            @validated="validated"
            @update:value="update_amount"
          ></memlist-text-input>
        </div>
      </div>

      <div class="description">{{ item.description }}</div>

      <button class="extra-item-button">{{ $t('COMMON.CONTINUE') }}</button>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {
        shop_item_id: 11,
        type: 'SHIPPING_ITEM',
        selected: false,
        name: 'Extra Item 1',
        amount: '5',
        currency: 'SEK',
        description: 'Description for extra item 1',
        img_url: 'http://localhost:3020/dlfile/7TZe1m6WW6kthf397sUy5OFPSKUvKGH6Cwa6hXW4QrbdNSbkL0pqo9EwFyLIr0qZ',
      },
      cart_items: [],
    };
  },
  methods: {
    update_amount(field, value) {},
    validated() {},
    validate() {},
  },
};
</script>

<style scoped>
/* Container for the entire page */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
}

/* Header styling */
.header {
  text-align: center;
  margin-bottom: 20px;
}

/* Section container */
.section-container {
  width: 100%;
  max-width: 600px; /* Constrain the width on larger screens */
  margin: 20px 0;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Input container to give padding and spacing */
.input-container {
  margin-bottom: 20px;
}

/* Description styling */
.description {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

/* Button styling */
.extra-item-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #2d9bf0;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}

.extra-item-button:hover {
  background-color: #2380c1;
}

/* Continue button styling */
.continue-button {
  margin-top: 20px;
  max-width: 700px;
  width: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* On mobile, the container will take the full width */
  .section-container {
    width: 100%;
    padding: 10px;
    box-shadow: none; /* Remove shadow on mobile for a flatter design */
    border-radius: 0;
  }

  .extra-item-button,
  .continue-button {
    width: 100%;
  }
}
</style>
